@import 'antd/dist/reset.css';

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    -webkit-background-clip: text;
}

::-webkit-scrollbar {
    width: 6px;
}

::-webkit-scrollbar-track {
    background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
    background: #888;
}

::-webkit-scrollbar:horizontal {
    height: 6px;
}

::-webkit-scrollbar-thumb:hover {
    background: #555;
}

.upgrade-modal .mediakit-modal-confirm-paragraph {
    max-width: 100% !important;
}

.mediakit-form-item.mediakit-form-item-with-help.mediakit-form-item-has-error {
    animation: mediakit-form-item-has-error 0.4s forwards;
}

.mb-0 {
    margin-bottom: 0 !important;
}

.w-100 {
    width: 100%;
}

.cursor-pointer {
    cursor: pointer;
}

.overflow-hidden {
    height: 100vh !important;
    overflow: hidden !important;
    position: relative !important;
}

.smooth-transition {
    transition: height 0.05s ease !important;
}

@keyframes wiggle {
    0%, 100% {
        border: 1px solid #fff !important;
        transform: rotate(0deg);
    }
    25% {
        border: 1px solid #fff !important;
        transform: rotate(-3deg);
    }
    75% {
        border: 1px solid #fff !important;
        transform: rotate(3deg);
    }
}

@keyframes mediakit-form-item-has-error {
    0% {
        padding-bottom: 0;
    }

    100% {
        padding-bottom: 24px;
    }
}

:root {
    --tw-shadow-color: #e5e7eb;
}

.shadow-lg {
    --tw-shadow: 0 10px 15px -3px rgb(0 0 0 / .1), 0 4px 6px -4px rgb(0 0 0 / .1);
    --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000), var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
}

@media (max-width: 991px) {
    .drawer-wrapper {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }

    .drawer-wrapper .mediakit-drawer-content {
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
    }
}

@media (pointer: coarse) {
    @media (min-width: 769px) {
        #tidio-chat-iframe {
            max-width: calc(100vw - 262px) !important;
            max-height: calc(100dvh - 64px) !important;
        }
    }

    @media (max-width: 768px) {
        #tidio-chat-iframe {
            max-height: calc(100dvh - 64px) !important;
        }
    }
}